import React from 'react'
import Breadcrumb from './Breadcrumb'

function Contact() {
    return (
        <div className='content'>
            <Breadcrumb name="Kontakt"/>

        </div>
    )
}

export default Contact