import React from 'react'
import Breadcrumb from './Breadcrumb'

function Other() {
  return (
    <div className='content'>
        <Breadcrumb name="Sonstiges"/>
    </div>
  )
}

export default Other